<mat-toolbar class="breadcrumb py-3">
  <div class="col-6">
    <app-breadcrumb
      [nodes]="breadcrumbItems()"
      (menuClicked)="drawer.toggle()"
      (nodeClicked)="getBoardsOfBranch('regular', $event?.id)"
    ></app-breadcrumb>
  </div>
  <div class="col-6 text-right d-flex justify-content-end align-items-center">
    <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ 'field.search' | translate }}</mat-label>
      <input matInput aria-label="Search" [matAutocomplete]="searchResult" [formControl]="searchControl" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
</mat-toolbar>

<mat-drawer-container autosize>
  <mat-drawer
    #drawer
    class="board-sidenav"
    mode="side"
    (openedStart)="store.loadBoardsForSidebar({ pinId: null, boardId: null })"
  >
    <app-tree-board-list
      *ngFor="let pinned of shownPinnedSidebarBoards$ | async"
      [isPinned]="true"
      [data]="pinned.boards"
      [expandableDecidingKey]="'hasAuthorizedChild'"
      [isClickableDecidingKey]="'isAuthorized'"
      [clickPayloadDecidingKey]="'key'"
      [isDataLoading]="
        (store.sidebarDataStatus$ | async) === store.ELoadStatus.loading ||
        (store.boardsStatus$ | async) === store.ELoadStatus.loading ||
        (isCurrentUserLoadInProgress$ | async) ||
        (store.loadPinnedStatus$ | async) === store.ELoadStatus.loading
      "
      (fetchItems)="store.loadBoardsForSidebar({ pinId: pinned.pinId, boardId: $event })"
      (collapseItem)="store.removeFromSidebar({ pinId: pinned.pinId, boardId: $event })"
      (unpinClick)="togglePin(pinned.pinId, true)"
      (nodeClick)="navigateToBoard($event)"
    ></app-tree-board-list>

    <app-tree-board-list
      [data]="(shownUnpinnedSidebarBoards$ | async)!"
      [expandableDecidingKey]="'hasAuthorizedChild'"
      [isClickableDecidingKey]="'isAuthorized'"
      [clickPayloadDecidingKey]="'key'"
      (fetchItems)="store.loadBoardsForSidebar({ pinId: null, boardId: $event })"
      (collapseItem)="store.removeFromSidebar({ pinId: null, boardId: $event })"
      (nodeClick)="navigateToBoard($event)"
    ></app-tree-board-list>
  </mat-drawer>
  <div *ngIf="store.data$().length" class="d-flex justify-content-center position-absolute m-auto w-100 pt-2 pb-2 z-1">
    <app-quick-item-generator (afterCreate)="refreshAllLists()"></app-quick-item-generator>
  </div>
  <div class="px-3 pt-3 boards-list">
    <div *ngIf="!breadcrumbItems().length && store.quickItemsFormatted().length" class="row">
      <h4 class="col-md-12 m-0 pb-3">
        <mat-icon class="v-middle">format_list_bulleted</mat-icon>
        {{ 'field.quickCreatedItems' | translate }}
      </h4>
      <app-item-card
        *ngFor="let quickItem of store.quickItemsFormatted()"
        class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-sm-12 item-card"
        [item]="quickItem"
        (cardClick)="openItemEditModal(quickItem.id)"
      ></app-item-card>
    </div>
    <div *ngIf="!breadcrumbItems().length && store.pinnedBoards().length" class="row">
      <h4 class="col-md-12 m-0 pb-3">
        <mat-icon class="v-middle">push_pin</mat-icon>
        {{ 'field.pinnedBoards' | translate }}
      </h4>
      <app-board-card
        *ngFor="let board of sortedPinnedBoards()"
        class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-sm-12"
        [board]="board"
        [isDataLoading]="
          (store.sidebarDataStatus$ | async) === store.ELoadStatus.loading ||
          (store.boardsStatus$ | async) === store.ELoadStatus.loading ||
          (isCurrentUserLoadInProgress$ | async) ||
          (store.loadPinnedStatus$ | async) === store.ELoadStatus.loading
        "
        (treeIconClick)="getBoardsOfBranch('pinned', board.id)"
        (pinToggle)="togglePin(board.id, true)"
        (cardClick)="navigateToBoard(board.key)"
      ></app-board-card>
    </div>
    <div class="row">
      <h4 *ngIf="!breadcrumbItems().length && shownBoards().length" class="col-md-12 m-0 pb-3">
        <mat-icon class="v-middle">dashboard</mat-icon>
        {{ 'field.boards' | translate }}
      </h4>
      <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-sm-12" *ngFor="let board of shownBoards()">
        <app-board-card
          [board]="board"
          [isDataLoading]="
            (store.sidebarDataStatus$ | async) === store.ELoadStatus.loading ||
            (store.boardsStatus$ | async) === store.ELoadStatus.loading ||
            (isCurrentUserLoadInProgress$ | async) ||
            (store.loadPinnedStatus$ | async) === store.ELoadStatus.loading
          "
          (treeIconClick)="getBoardsOfBranch('regular', board.id)"
          (pinToggle)="togglePin(board.id, board.isPinned)"
          (cardClick)="navigateToBoard(board.key)"
        ></app-board-card>
      </div>

      <div
        class="col-lg-12 text-center"
        *ngIf="store.data$().length === 0 && (store.boardsStatus$ | async) === store.ELoadStatus.success"
      >
        <mat-icon>eco</mat-icon>
        <br />
        {{ 'page.home.noBoardInfo' | translate }}
      </div>
    </div>
  </div>
</mat-drawer-container>

<mat-autocomplete #searchResult="matAutocomplete" class="home-search" (closed)="searchControl.setValue('')">
  <mat-option
    disabled
    *ngIf="
      searchControl.value &&
      (searchObs$ | async) === searchControl.value &&
      (store.searchStatus$ | async) === store.ELoadStatus.success &&
      !(boardSearchResults$ | async)?.length &&
      !(issueSearchResults$ | async)?.length &&
      !(actionSearchResults$ | async)?.length
    "
  >
    {{ 'system.message.notFound' | translate }}
  </mat-option>
  <mat-optgroup label="{{ 'field.board' | translate }}" *ngIf="(boardSearchResults$ | async)?.length">
    @for (result of boardSearchResults$ | async; track result) {
      <mat-option [value]="result.name" (click)="navigateToBoard(result.key)">
        <mat-icon>grid_view</mat-icon>
        <small>{{ result.key }}</small> |
        <span>{{ result.name }}</span>
      </mat-option>
    }
    <mat-option *ngIf="store.isLoadMoreAvailableForBoards$ | async">
      <div class="absolute-zero" (click)="loadMoreSearchResults('boards', $event)">
        <mat-icon class="v-middle">refresh</mat-icon>
        {{ 'page.home.loadMore' | translate }}
      </div>
    </mat-option>
  </mat-optgroup>
  <mat-optgroup label="{{ 'business.issue' | translate }}" *ngIf="(issueSearchResults$ | async)?.length">
    @for (result of issueSearchResults$ | async; track result) {
      <mat-option [value]="result.name" (click)="openItemEditModal(result.id)">
        <mat-icon>description</mat-icon>
        <span>{{ result.name }}</span>
      </mat-option>
    }
    <mat-option *ngIf="store.isLoadMoreAvailableForIssues$ | async">
      <div class="absolute-zero" (click)="loadMoreSearchResults('issue', $event)">
        <mat-icon class="v-middle">refresh</mat-icon>
        {{ 'page.home.loadMore' | translate }}
      </div>
    </mat-option>
  </mat-optgroup>
  <mat-optgroup label="{{ 'business.action' | translate }}" *ngIf="(actionSearchResults$ | async)?.length">
    @for (result of actionSearchResults$ | async; track result) {
      <mat-option [value]="result.name" (click)="openItemEditModal(result.id)">
        <mat-icon>description</mat-icon>
        <span>{{ result.name }}</span>
      </mat-option>
    }

    <mat-option *ngIf="store.isLoadMoreAvailableForActions$ | async">
      <div class="absolute-zero" (click)="loadMoreSearchResults('action', $event)">
        <mat-icon class="v-middle">refresh</mat-icon>
        {{ 'page.home.loadMore' | translate }}
      </div>
    </mat-option>
  </mat-optgroup>
</mat-autocomplete>
