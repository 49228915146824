import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  computed,
  effect,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDialog, MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatInput, MatSuffix } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { isEqual } from 'lodash';
import { Subscription, distinctUntilChanged, filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { EDialogWidth } from '../../../core/constants/ui.constants';
import { TrimmedRequiredValidator } from '../../../core/validators/trimmed-required.validator';
import {
  MemoryStoredItemAttachmentFile,
  TAttachmentRules,
  TExternalSelectedFilesSignal,
} from '../../../pages/items/items-attachments/items-attachments.interface';
import { ItemsAttachmentsStore } from '../../../pages/items/items-attachments/items-attachments.store';
import { QuickItemAttachmentsComponent } from '../../../pages/items/items-attachments/quick-item-attachments/quick-item-attachments.component';
import { ItemsModalComponent } from '../../../pages/items/items-modal/items-modal.component';
import { TItemModalComponentData } from '../../../pages/items/items-modal/items-modal.interface';
import { IItemBase } from '../../../pages/items/items.interface';
import { EItemType } from '../../../pages/settings/fields-field-sets/field-sets/field-sets.interface';
import { ELoadStatus } from '../../../state/state.interface';
import { DeviceCameraUtilitiesService } from '../../../utilities/device-camera-utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { ESnackbar, SnackbarService } from '../../../utilities/snackbar.service';
import { DeviceCameraModalComponent } from '../device-camera-modal/device-camera-modal.component';
import { ErrorMessagesComponent } from '../error-messages/error-messages.component';
import { IDropdownOption, MatDropdown } from '../mat-dropdown/mat-dropdown.component';
import { IDropdownSettings } from '../mat-dropdown/scw-mat-dropdown.interface';
import { EQuickItemGeneratorMode, IAddQuickItem } from './quick-item-generator.interface';
import { QuickItemGeneratorStore } from './quick-item-generator.store';

@Component({
  imports: [
    ReactiveFormsModule,
    QuickItemAttachmentsComponent,
    NgIf,
    MatDropdown,
    MatIconButton,
    MatInput,
    MatDialogContent,
    MatFormField,
    MatButton,
    ErrorMessagesComponent,
    NgClass,
    MatSuffix,
    MatDialogActions,
    MatIcon,
    AsyncPipe,
    TranslateModule,
    MatLabel,
    MatError,
  ],
  providers: [QuickItemGeneratorStore, ItemsAttachmentsStore],
  selector: 'app-quick-item-generator',
  standalone: true,
  styleUrl: './quick-item-generator.component.scss',
  templateUrl: './quick-item-generator.component.html',
})
export class QuickItemGeneratorComponent implements OnInit, OnDestroy {
  @Output() public afterCreate = new EventEmitter<void>();

  public formGroup = new FormGroup({
    assignee: new FormControl([] as IDropdownOption[]),
    boardId: new FormControl([] as IDropdownOption[], [Validators.required]),
    description: new FormControl(''),
    name: new FormControl(''),
  });
  public isAssigneeToggled = false;

  public readonly mode = signal<EQuickItemGeneratorMode>(EQuickItemGeneratorMode.minimized);
  private readonly isFormEdited = toSignal(
    this.formGroup.valueChanges.pipe(map((form) => Boolean(form.name || form.description))),
    { initialValue: false },
  );
  private readonly extendedTitleLabel = this.translate.instant('field.title');
  private readonly unsavedChangesTitleLabel = this.translate.instant('button.unsavedChanges');
  private readonly minimizedPlaceholderTitleLabel = this.translate.instant('page.home.addAnAction');
  public readonly titleLabel: Signal<string> = computed(() => {
    if (this.mode() === EQuickItemGeneratorMode.extended) {
      return this.extendedTitleLabel;
    }

    return this.isFormEdited() ? this.unsavedChangesTitleLabel : this.minimizedPlaceholderTitleLabel;
  });

  public selectedFileChangeEvent: TExternalSelectedFilesSignal | undefined;
  public capturedPhotoFile: File | undefined;
  public attachmentRules!: TAttachmentRules;
  public isDeviceCameraAvailable = false;
  public isMaxFilesCountExceeded = false;
  public pendingAttachmentFiles: MemoryStoredItemAttachmentFile[] = [];
  public EQuickItemGeneratorMode = EQuickItemGeneratorMode;
  public boardDropdownConfiguration: IDropdownSettings = {
    hasDelayedDefaultValue: true,
    singleSelection: true,
    text: this.translate.instant('field.board'),
  };
  public assigneeDropdownConfiguration: IDropdownSettings = {
    hasDelayedDefaultValue: true,
    singleSelection: true,
    text: this.translate.instant('field.assignee'),
  };
  public readonly addItemLabel = this.translate.instant('button.addContext', {
    context: this.translate.instant('field.item'),
  });

  public readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly store: QuickItemGeneratorStore,
    private readonly eRef: ElementRef,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    private readonly itemsAttachmentsStore: ItemsAttachmentsStore,
  ) {
    effect(
      () => {
        if (this.mode() === EQuickItemGeneratorMode.minimized) {
          this.formGroup.controls.name.clearValidators();
          this.formGroup.controls.name.updateValueAndValidity({ emitEvent: false });
        } else {
          if (!this.formGroup.controls.boardId.value?.length) {
            this.store.getRecommendedBoard();
          }

          this.store.getBoardOptions({});
          this.formGroup.controls.name.setValidators([Validators.required, TrimmedRequiredValidator()]);
          this.formGroup.controls.name.reset(this.formGroup.controls.name.value);
        }
      },
      { allowSignalWrites: true },
    );
  }

  public async ngOnInit(): Promise<void> {
    DeviceCameraUtilitiesService.isDeviceCameraAvailable()
      .then((isAvailable) => (this.isDeviceCameraAvailable = isAvailable))
      .catch(() => {
        this.isDeviceCameraAvailable = false;
      });

    this.store.recommendedBoard$.subscribe((recommendedBoard): void => {
      this.formGroup.controls.boardId.patchValue(
        recommendedBoard?.boardId ? [{ id: recommendedBoard.boardId!, name: recommendedBoard.boardName! }] : [],
      );
    });
    this.formGroup.controls.boardId.valueChanges
      .pipe(
        filter((boardSelection) => Boolean(boardSelection?.length) && boardSelection![0].name !== '...'),
        distinctUntilChanged(isEqual),
      )
      .subscribe((boardSelection: IDropdownOption[]) => {
        this.formGroup.controls.assignee.reset([]);
        this.store.getBoardMembersData({ boardId: boardSelection![0].id });
      });
    this.store
      .select((state) => state.addLoading)
      .pipe(filter((status) => status === ELoadStatus.success))
      .subscribe(() => {
        this.afterCreate.emit();
        this.snackbarService.open(this.translate.instant('system.message.changesSuccessfully'), ESnackbar.success);
      });

    this.subscriptions.push(
      this.store.dataQuickItemAfterAdd$.subscribe((data: IItemBase | null) => {
        if (!data) {
          return;
        }

        const files = _.cloneDeep(this.pendingAttachmentFiles.map((attachment) => attachment.file));
        this.pendingAttachmentFiles = [];
        this.capturedPhotoFile = undefined;

        this.itemsAttachmentsStore.addAttachments({
          boardId: data.boardId,
          files,
          itemId: data.id,
        });

        this.selectedFileChangeEvent = undefined;
        this.isMaxFilesCountExceeded = false;
      }),
    );
  }

  @HostListener('document:click', ['$event'])
  public clickOut(event: Event): void {
    const eventTarget: HTMLElement = event.target as HTMLElement;
    const bypassMinimizeRequest: boolean =
      eventTarget.tagName === 'VIDEO' ||
      eventTarget?.parentElement?.tagName === 'APP-DEVICE-CAMERA-MODAL' ||
      Object.values(eventTarget.classList).includes('camera-switch') ||
      eventTarget.id === 'cameraIcon';

    if (
      !(this.eRef.nativeElement as HTMLElement).contains(eventTarget) &&
      !bypassMinimizeRequest &&
      this.mode() === EQuickItemGeneratorMode.extended
    ) {
      this.mode.set(EQuickItemGeneratorMode.minimized);
      event.stopPropagation();
    }
  }

  public openFullGenerator(): void {
    this.mode.set(EQuickItemGeneratorMode.extended);
  }

  public submitQuickItem(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.store.addQuickItemData(this.prepareSubmitData());

    this.formGroup.reset({
      assignee: [],
      boardId: [],
      description: '',
      name: '',
    });
    this.mode.set(EQuickItemGeneratorMode.minimized);
  }

  public onBoardSearch(search: string): void {
    this.store.getBoardOptions({ search: { searchedFields: ['name'], searchText: search } });
  }

  public onBoardAssigneeSearch(search: string): void {
    const boardId = this.formGroup.controls.boardId.value;

    if (!boardId?.length) {
      return;
    }

    this.store.getBoardMembersData({ boardId: boardId[0].id, search });
  }

  public toggleAssignee(): void {
    this.isAssigneeToggled = !this.isAssigneeToggled;
  }

  public openAddItemModal(): void {
    this.dialog.open(ItemsModalComponent, {
      data: {
        quickItem: {
          ...this.formGroup.value,
          itemType: [{ id: EItemType.action, name: this.translate.instant('business.action') }],
        },
        submitButtonText: this.addItemLabel,
        title: this.addItemLabel,
      } satisfies TItemModalComponentData,
      disableClose: true,
      width: EDialogWidth.large,
    });
  }

  public onFileChange(event: Event, inputElem?: HTMLInputElement): void {
    this.selectedFileChangeEvent = { event, inputElem };
  }

  public onPhotoCapture(file: File): void {
    this.capturedPhotoFile = file;
  }

  public onAttachmentRulesChange(rules: TAttachmentRules): void {
    this.attachmentRules = rules;
  }

  public onDeviceCameraAvailabilityChange(isAvailable: boolean): void {
    this.isDeviceCameraAvailable = isAvailable;
  }

  public onMemoryStoredAttachmentsChange(memoryStoredAttachments: MemoryStoredItemAttachmentFile[]): void {
    this.pendingAttachmentFiles = memoryStoredAttachments;
    this.isMaxFilesCountExceeded = this.pendingAttachmentFiles.length >= 5;
  }

  public onMaxFilesCountExceededChange(isExceeded: boolean): void {
    if (this.pendingAttachmentFiles.length >= 5) {
      this.isMaxFilesCountExceeded = isExceeded;
    }
  }

  public openCamera(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.mode() !== EQuickItemGeneratorMode.extended) {
      this.openFullGenerator();
    }

    if (!this.isDeviceCameraAvailable) {
      return;
    }

    const dialogRef = this.dialog.open(DeviceCameraModalComponent, {
      width: '600px',
      height: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onPhotoCapture(result);
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.selectedFileChangeEvent = undefined;
    this.pendingAttachmentFiles = [];
    this.capturedPhotoFile = undefined;
  }

  private prepareSubmitData(): IAddQuickItem {
    return {
      boardId: this.formGroup.value.boardId?.[0]?.id ?? 0,
      description: FormUtilitiesService.trimIfString(this.formGroup.value.description) || null,
      isQuick: true,
      itemType: EItemType.action,
      name: FormUtilitiesService.trimIfString(this.formGroup.value.name) ?? '',
      ...(this.isAssigneeToggled && this.formGroup.controls.assignee.value?.length
        ? { assigneeId: this.formGroup.controls.assignee.value[0].id }
        : {}),
    };
  }
}
