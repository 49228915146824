import { Injectable, Signal } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of } from 'rxjs';
import { IBaseCrudResponse } from '../utilities/http-utilities.service';
import { ELoadStatus } from './state.interface';

export interface IBaseDatatableState<RowData> {
  bulkCrudLoading: ELoadStatus;
  bulkOperationFailedData: IBaseCrudResponse[];
  data: RowData[];
  dataLoading: ELoadStatus;
  dataTotal: number;
  singleCrudLoading: ELoadStatus;
}

@Injectable()
export abstract class BaseDatatableStore<
  RowData extends { id: number },
  State extends IBaseDatatableState<RowData>,
> extends ComponentStore<State> {
  readonly data$: Signal<RowData[]> = this.selectSignal((state: State) => state.data);
  readonly dataTotal$: Signal<number> = this.selectSignal((state: State) => state.dataTotal);
  readonly ELoadStatus = ELoadStatus;

  readonly deleteOne = this.effect((trigger$: Observable<number>) => of(trigger$));
  readonly deleteBulk = this.effect((trigger$: Observable<number[]>) => of(trigger$));
}
