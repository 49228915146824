import { Dayjs } from 'dayjs';
import { IDateRangePickerDate } from '../../shared/components/date-range-picker/date-range-picker.interface';
import { IDropdownOption } from '../../shared/components/mat-dropdown/mat-dropdown.component';
import { IUser } from '../../state/user/user.interface';
import { IBoard } from '../settings/boards/boards.interface';
import {
  IBoardItemConfigurationBase,
  IBoardItemConfigurationJoins,
  IFieldSetBase,
} from '../settings/fields-field-sets/field-sets/field-sets.interface';
import { EFieldType } from '../settings/fields-field-sets/fields/fields.interface';

export interface IItemBase<DateType extends Dayjs | string = Dayjs> {
  assigneeId: number | null;
  boardId: number;
  boardItemConfigurationId: number;
  createdAt: string;
  createdBy: number;
  currentWorkflowStepId: number;
  description: string | null;
  dueDate: string | null;
  fieldSetId: number;
  id: number;
  isQuick: boolean;
  key: string;
  name: string;
  updatedAt: string;
  updatedBy: number;
  values: TItemCustomFieldValue<DateType>[];
}

export type TItemCustomFieldValue<DateType extends Dayjs | string = Dayjs> =
  | ICustomOptionFieldValue
  | ICustomDateRangeFieldValue<DateType>
  | ICustomTextFieldValue;

export interface IItemJoins<DateType extends Dayjs | string = Dayjs> extends IItemBase<DateType> {
  assignee: IUser | null;
  board: IBoard;
  boardItemConfiguration: IBoardItemConfigurationBase & IBoardItemConfigurationJoins & { workflow: TWorkflow };
  currentWorkflowStep: IWorkflowStepBase;
  fieldSet: IFieldSetBase;
  reporter: IUser;
}

export interface IItemCustomFieldBase {
  fieldId: number;
  fieldName: string;
  type: EFieldType;
}

export interface ICustomOptionFieldValue extends IItemCustomFieldBase {
  selectedValue: IDropdownOption[] | null;
  type:
    | EFieldType.singleSelectDropdown
    | EFieldType.multiSelectDropdown
    | EFieldType.singleSelectCheckbox
    | EFieldType.multiSelectCheckbox;
}

export interface ICustomDateRangeFieldValue<DateType extends Dayjs | string = Dayjs> extends IItemCustomFieldBase {
  selectedValue: IDateRangePickerDate<DateType>;
  type: EFieldType.date;
}

export interface ICustomTextFieldValue extends IItemCustomFieldBase {
  selectedValue: string;
  type: EFieldType.text | EFieldType.textArea | EFieldType.wiki;
}

export interface IWorkflowStepBase {
  id: number;
  isDefault: boolean;
  name: string;
  statusCategory: TStatusCategory;
}

export type TStatusCategory = 'done' | 'inProgress' | 'todo';

export type TUrlItemScope = 'all' | 'self' | null;

export interface IBoardItemsInitialData {
  boardTreeStructure: IBoardTreeStructure[];
  initialBoard?: IDropdownOption & { key: string };
  item?: IItemBase<string>;
}

export interface IBoardTreeStructure {
  children: IBoardTreeStructure[];
  id: number;
}

export interface IWorkflowBase {
  id: number;
  name: string;
}

export interface IWorkflowJoins {
  workflowStepAssignments: TWorkflowStepAssignment[];
}

export interface IWorkflowStepAssignmentBase {
  id: number;
  nextWorkflowStepId: number;
  workflowStepId: number;
}

export interface IWorkflowStepAssignmentJoins {
  nextWorkflowStep?: IWorkflowStepBase | null;
  workflowStep: IWorkflowStepBase;
}

export type TWorkflow = IWorkflowBase & IWorkflowJoins;
export type TWorkflowStepAssignment = IWorkflowStepAssignmentBase & IWorkflowStepAssignmentJoins;

export type TUrlResetType = 'itemKey' | 'full';

export enum EWorkflow {
  done = 'done',
  inProgress = 'inProgress',
  todo = 'todo',
}

export enum EWorkflowIcon {
  adjust = 'adjust',
  schedule = 'schedule',
  task_alt = 'task_alt',
}

export enum EChipColor {
  blue = 'blue',
  gray = 'gray',
  green = 'green',
}

export enum EUserAssignee {
  unassignedUser = -1,
}
