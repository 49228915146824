import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  Component,
  OnInit,
  Signal,
  TemplateRef,
  ViewChild,
  WritableSignal,
  computed,
  effect,
  signal,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger, MatOptgroup, MatOption } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get, partition, sortBy } from 'lodash';
import { Observable, debounceTime, distinctUntilChanged, merge, shareReplay, startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TReducerState } from '../../app.config';
import { EDialogWidth } from '../../core/constants/ui.constants';
import { TreeBoardListComponent } from '../../layout/tree-board-list/tree-board-list.component';
import { BreadcrumbComponent, IBreadcrumbNode } from '../../shared/components/breadcrumb/breadcrumb.component';
import { ItemCardComponent } from '../../shared/components/item-card/item-card.component';
import { QuickItemGeneratorComponent } from '../../shared/components/quick-item-generator/quick-item-generator.component';
import { ELoadStatus } from '../../state/state.interface';
import { userActions } from '../../state/user/user.actions';
import { IPageConfiguration } from '../../state/user/user.interface';
import { HttpUtilitiesService } from '../../utilities/http-utilities.service';
import { SnackbarService } from '../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../app/base-crud-page.component';
import { ItemsModalComponent } from '../items/items-modal/items-modal.component';
import { IBoard } from '../settings/boards/boards.interface';
import { BoardCardComponent } from './board-card/board-card.component';
import { IHomeBoard, ISidebarBoard } from './home.interface';
import { HomeStore, IBoardComponentState, ISearchResult } from './home.store';

@Component({
  imports: [
    MatToolbar,
    BreadcrumbComponent,
    MatFormField,
    MatIcon,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatDrawerContainer,
    MatDrawer,
    TreeBoardListComponent,
    NgForOf,
    AsyncPipe,
    QuickItemGeneratorComponent,
    TranslateModule,
    ItemCardComponent,
    BoardCardComponent,
    MatAutocomplete,
    MatOptgroup,
    MatOption,
    NgIf,
    MatInput,
    MatLabel,
  ],
  providers: [HomeStore, HttpUtilitiesService],
  selector: 'app-home',
  standalone: true,
  styleUrl: './home.component.scss',
  templateUrl: './home.component.html',
})
export class HomeComponent extends BaseCrudPageComponent<IBoard, IBoardComponentState> implements OnInit {
  @ViewChild('searchDialog') searchDialog!: TemplateRef<HTMLElement>;

  public readonly searchControl: FormControl<string> = new FormControl();
  public readonly searchObs$ = this.searchControl.valueChanges.pipe(
    startWith(''),
    distinctUntilChanged(),
    debounceTime(600),
    shareReplay(1),
  );
  public readonly user = this.globalStore.selectSignal((state) => state.user.user);
  public readonly isCurrentUserLoadInProgress$ = this.globalStore.select(
    (state) =>
      state.user.getCurrentUserStatus === ELoadStatus.loading ||
      state.user.updateUserConfigurationsStatus === ELoadStatus.loading,
  );

  public readonly breadcrumbItems: WritableSignal<IBreadcrumbNode[]> = signal([]);

  public readonly sortedPinnedBoards = computed(() =>
    sortBy(this.store.pinnedBoards(), (board) => board.name.toLowerCase()),
  );
  public readonly shownBoards: Signal<IHomeBoard[]> = computed(() =>
    sortBy(
      this.store
        .data$()
        .filter(
          (board) =>
            this.breadcrumbItems().length ||
            !this.store.pinnedBoards().some((pinnedBoard) => pinnedBoard.id === board.id),
        ),
      (board) => board.name.toLowerCase(),
    ),
  );

  public readonly shownPinnedSidebarBoards$ = this.store.sidebarData$.pipe(
    map((data) =>
      sortBy(data.pinned, (pinnedContainer) =>
        pinnedContainer.boards.find((board) => board.parentId === null)?.name.toLowerCase(),
      ),
    ),
  );

  public readonly shownUnpinnedSidebarBoards$: Observable<ISidebarBoard[]> = this.store.sidebarData$.pipe(
    map((sidebarData) => {
      const pinnedIds = sidebarData.pinned.map((pinnedContainer) => pinnedContainer.pinId);
      const outermostPinnedBoardIds = sidebarData.regular
        .filter((board) => board.parentId === null && pinnedIds.includes(board.id))
        .map((board) => board.id);

      return sortBy(
        sidebarData.regular.filter(
          (board) =>
            !outermostPinnedBoardIds.includes(board.id) &&
            !board.parents.some((parentId) => outermostPinnedBoardIds.includes(parentId)),
        ),
        (board) => board.name.toLowerCase(),
      );
    }),
  );

  public readonly actionSearchResults$ = merge(
    this.store.select((state) => state.searchFields.action).pipe(map((searchData) => searchData.results)),
    this.searchControl.valueChanges.pipe(map(() => [])),
  );
  public readonly boardSearchResults$ = merge(
    this.store.select((state) => state.searchFields.boards).pipe(map((searchData) => searchData.results)),
    this.searchControl.valueChanges.pipe(map(() => [])),
  );
  public readonly issueSearchResults$ = merge(
    this.store.select((state) => state.searchFields.issue).pipe(map((searchData) => searchData.results)),
    this.searchControl.valueChanges.pipe(map(() => [])),
  );

  private readonly isScreenXl = toSignal(
    this.breakpointObserver.observe([Breakpoints.XLarge]).pipe(map((result) => result.matches)),
    {
      initialValue: false,
    },
  );

  constructor(
    public readonly store: HomeStore,
    private readonly dialog: MatDialog,
    translate: TranslateService,
    snackbar: SnackbarService,
    private readonly globalStore: Store<TReducerState>,
    private readonly router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfUser',
      multiContext: 'field.boards',
      nameProperty: 'name',
      singleContext: 'field.board',
    });

    effect(
      () => {
        this.user();
        this.store.loadBoardsForDashboard(untracked(() => store.lastLoadData()));

        if (untracked(() => !this.breadcrumbItems().length)) {
          this.store.loadPinnedBoardsForDashboard();
        }
      },
      { allowSignalWrites: true },
    );
  }

  public ngOnInit(): void {
    this.refreshListedItems();
    this.searchObs$
      .pipe(filter((search) => search !== null && search?.length >= 2))
      .subscribe((search: string | null): void => {
        this.store.loadInitialSearch(search!);
      });
    this.store
      .select((state) => state.lastLoadData)
      .subscribe((lastLoadData) => {
        if (lastLoadData.id === null) {
          this.breadcrumbItems.set([]);
        }

        const isBreadcrumbItemAlreadyIn = this.breadcrumbItems().some((item) => item.id === lastLoadData.id);

        if (lastLoadData.breadcrumbUpdate && !isBreadcrumbItemAlreadyIn) {
          this.breadcrumbItems.set([...this.breadcrumbItems(), lastLoadData.breadcrumbUpdate]);
        }
      });
  }

  public loadMoreSearchResults(field: keyof ISearchResult, $event?: Event): void {
    if ($event) {
      $event.stopPropagation();
    }

    this.store.loadMoreSearchResults({ field, searchText: this.searchControl.value! });
  }

  public getBoardsOfBranch(boardDataSource: 'pinned' | 'regular', id?: number): void {
    if (!id) {
      this.store.loadPinnedBoardsForDashboard();
      this.store.loadBoardsForDashboard({ breadcrumbUpdate: undefined, id: null });

      return;
    }

    const dataSource = boardDataSource === 'pinned' ? this.store.pinnedBoards() : this.store.data$();

    const item: IHomeBoard | undefined = dataSource.find((board: IHomeBoard) => board.id === id);

    this.store.loadBoardsForDashboard({ breadcrumbUpdate: item, id });
  }

  public togglePin(boardId: number, isCurrentlyPinned: boolean): void {
    const componentName = 'HomeComponent';

    const pageConfiguration: IPageConfiguration['HomeComponent'] = get(
      this.user(),
      `pageConfiguration.${componentName}`,
      [],
    );

    const [pinnedBoardsConfigurations, restOfTheHomeConfigurations]: [
      IPageConfiguration['HomeComponent'],
      IPageConfiguration['HomeComponent'],
    ] = partition(pageConfiguration, (configuration) => configuration.name === 'pinnedBoards');

    const pinnedBoardsConfiguration = pinnedBoardsConfigurations[0] ?? { name: 'pinnedBoards', value: [] };

    const updatedPinnedList = isCurrentlyPinned
      ? pinnedBoardsConfiguration?.value.filter((value) => value !== boardId)
      : [...pinnedBoardsConfiguration?.value, boardId];

    this.globalStore.dispatch(
      userActions.updateUserConfigurations({
        pageConfiguration: {
          ...(this.user()!.pageConfiguration ?? {}),
          [componentName]: [
            ...restOfTheHomeConfigurations,
            {
              ...pinnedBoardsConfiguration,
              value: updatedPinnedList,
            },
          ],
        },
      }),
    );
  }

  public navigateToBoard(boardKey: string): void {
    this.router.navigate([`/items/${boardKey}/all`]);
  }

  public refreshAllLists(): void {
    this.refreshListedItems();
    this.store.loadBoardsForDashboard(this.store.lastLoadData());
    this.store.loadPinnedBoardsForDashboard();
  }

  public refreshListedItems(): void {
    this.store.loadQuickItems({
      fields: ['name', 'key', 'createdAt', 'assignee||name'],
      filters: [{ field: 'isQuick', ids: [true] }],
      join: ['assignee||name'],
      limit: this.isScreenXl() ? 6 : 4,
      sort: [{ active: 'createdAt', direction: 'desc' }],
    });
  }

  public openItemEditModal(itemId: number): void {
    this.subscriptions.push(
      this.dialog
        .open(ItemsModalComponent, {
          data: {
            idForEdit: itemId,
            submitButtonText: this.saveChanges,
          },
          disableClose: true,
          width: EDialogWidth.large,
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.refreshListedItems();
          }
        }),
    );
  }
}
