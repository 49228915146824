import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbar } from '@angular/material/toolbar';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TReducerState } from '../../app.config';
import { EDialogWidth } from '../../core/constants/ui.constants';
import { MonitoringService } from '../../core/error-handler/monitoring.service';
import { getApiUrl } from '../../core/interceptors/api-interceptor.interceptor';
import { ItemsModalComponent } from '../../pages/items/items-modal/items-modal.component';
import { IClientInfo, IClientInfoState } from '../../state/client-info/client-info.interface';
import { UserEffects } from '../../state/user/user.effects';

@Component({
  animations: [
    trigger('slideDownDisappear', [
      state(
        'void',
        style({
          transform: 'translateY(-100%)',
        }),
      ),
      transition('* => void', [animate('500ms ease-in-out', style({ transform: 'translateY(150%)' }))]),
    ]),
    trigger('slideDownAppear', [
      state(
        'void',
        style({
          transform: 'translateY(-100%)',
        }),
      ),
      transition('void => *', [animate('500ms ease-in-out', style({ transform: 'translateY(50%)' }))]),
    ]),
  ],
  imports: [
    MatToolbar,
    NgIf,
    MatMenuTrigger,
    MatIcon,
    TranslateModule,
    MatMenu,
    RouterLink,
    MatIconButton,
    MatButton,
    MatMenuItem,
  ],
  selector: 'app-toolbar',
  standalone: true,
  styleUrl: './toolbar.component.scss',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public isToolAdmin: boolean = UserEffects.getUser().isAdmin;
  public isBoardAdmin: boolean = UserEffects.getUser().authorizations.adminBoardIds?.length > 0;
  public clientInfo: IClientInfo | null = null;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly store: Store<TReducerState>,
    private readonly monitoringService: MonitoringService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('clientInfo').subscribe((clientInfoState: IClientInfoState) => {
        this.clientInfo = clientInfoState.clientInfo;
      }),
    );
  }

  public logout(): void {
    this.monitoringService.logoutAuthorizedUser();
    localStorage.clear();
    window.location.assign(
      `${getApiUrl()}/auth/logout?state=${window.location.href.replace(this.router.url, '/home')}`,
    );
  }

  public openItemModal(): void {
    const addItemLabel: string = this.translate.instant('toolbar.addAction');

    this.dialog.open(ItemsModalComponent, {
      data: {
        submitButtonText: addItemLabel,
        title: addItemLabel,
      },
      disableClose: true,
      width: EDialogWidth.large,
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
