<mat-card class="mb-3 cursor-pointer board-card" (click)="onCardClick($event)">
  <div class="row">
    <div class="col-6 text-left">
      <button *ngIf="board?.hasAuthorizedChild" mat-icon-button (click)="treeIconClick.emit()">
        <mat-icon>account_tree</mat-icon>
      </button>
    </div>
    <div class="col-6 text-right">
      <button
        mat-icon-button
        [disabled]="isDataLoading"
        (click)="pinToggle.emit()"
        matTooltip="{{ (board?.isPinned ? 'button.unpin' : 'button.pin') | translate }}"
      >
        <mat-icon [ngClass]="{ 'material-icons-outlined': !board?.isPinned }">push_pin</mat-icon>
      </button>
    </div>
  </div>

  <mat-card-content>
    <mat-card-title>{{ board?.name }}</mat-card-title>
    <mat-card-subtitle>{{ board?.key }}</mat-card-subtitle>
  </mat-card-content>

  <div class="row m-0">
    <mat-card-subtitle class="col-6 text-left counts-subtitle">
      {{ ('field.issues' | translate) + ': ' + (board?.numberOfIssues ?? 0) }}
    </mat-card-subtitle>
    <mat-card-subtitle class="col-6 text-right counts-subtitle">
      {{ ('field.actions' | translate) + ': ' + (board?.numberOfActions ?? 0) }}
    </mat-card-subtitle>
  </div>
</mat-card>
