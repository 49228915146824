import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ELoadStatus } from '../../../state/state.interface';
import {
  HttpUtilitiesService,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { EItemType } from '../../settings/fields-field-sets/field-sets/field-sets.interface';
import { TStatusCategory } from '../items.interface';

export interface IItemsKpiCardsState {
  data: IItemsKpiInformation[];
  dataLoading: ELoadStatus;
}

export interface IItemsKpiInformation {
  boardItemConfigurationItemType: EItemType;
  count: number;
  currentWorkflowStepStatusCategory: TStatusCategory;
}

@Injectable()
export class ItemsKpiCardsStore extends ComponentStore<IItemsKpiCardsState> {
  public readonly kpiInformation: Signal<IItemsKpiInformation[]> = this.selectSignal((state) => state.data);

  readonly loadItems = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getKpiInfo(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IItemsKpiInformation>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      data: [],
      dataLoading: ELoadStatus.initial,
    });
  }

  private getKpiInfo(params: HttpParams): Observable<IGetManyResponse<IItemsKpiInformation>> {
    return this.http.get<IGetManyResponse<IItemsKpiInformation>>('items/kpi-info', { params });
  }
}
