import { Component, effect, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, filter, Subscription } from 'rxjs';
import { HttpUtilitiesService } from '../../../utilities/http-utilities.service';
import { IItemsFilterCardFields } from '../items-filter-card/items-filter-card.interface';
import { IBoardTreeStructure } from '../items.interface';
import { ItemsService } from '../items.service';
import { TKpiCard, TKpiCardKey } from './items-kpi-cards.interface';
import { IItemsKpiInformation, ItemsKpiCardsStore } from './items-kpi-cards.store';

@Component({
  imports: [MatIcon, MatLabel, TranslateModule, MatCard],
  providers: [ItemsKpiCardsStore, HttpUtilitiesService],
  selector: 'app-items-kpi-cards',
  standalone: true,
  styleUrls: ['items-kpi-cards.component.scss'],
  templateUrl: './items-kpi-cards.component.html',
})
export class ItemsKpiCardsComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public filterCardData$ = new BehaviorSubject<Partial<IItemsFilterCardFields> | null>(null);
  @Input() public boardTreeStructure: IBoardTreeStructure[] = [];

  public readonly kpiCardOrder: TKpiCardKey[] = ['issue', 'action', 'todo', 'inProgress', 'done'];
  public kpiCardDetails: TKpiCard = {
    action: { count: 0, title: this.translate.instant('page.items.label.allActions') },
    done: { count: 0, icon: 'task_alt', iconColor: '#22962d', title: this.translate.instant('page.items.label.done') },
    inProgress: {
      count: 0,
      icon: 'schedule',
      iconColor: '#165aff',
      title: this.translate.instant('page.items.label.inProgress'),
    },
    issue: { count: 0, title: this.translate.instant('page.items.label.allIssues') },
    todo: { count: 0, icon: 'adjust', iconColor: '#1c2a4b', title: this.translate.instant('page.items.label.todo') },
  };
  private readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly store: ItemsKpiCardsStore,
    private readonly itemsService: ItemsService,
    private readonly translate: TranslateService,
  ) {
    effect(() => {
      this.kpiCardDetails = this.formatKpiInformation(this.store.kpiInformation());
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        filterCard: this.filterCardData$.pipe(filter(Boolean)),
      }).subscribe(({ filterCard }) => {
        this.loadItems(filterCard as IItemsFilterCardFields);
      }),
      this.itemsService.refreshItems$.subscribe(() => {
        this.loadItems(this.filterCardData$.value as IItemsFilterCardFields);
      }),
    );
  }

  private loadItems(filterCard: IItemsFilterCardFields): void {
    this.store.loadItems({
      filters: [...this.itemsService.prepareFilters(filterCard, this.boardTreeStructure)],
      groupBy: ['boardItemConfiguration.itemType', 'currentWorkflowStep.statusCategory'],
      join: [
        'board||name,key',
        'boardItemConfiguration||name,key,itemType,itemCategory',
        'boardItemConfiguration.itemCategory||name,isDefault,isActive',
        'fieldSet||name',
        'currentWorkflowStep||name,isDefault,statusCategory',
        'assignee||name',
        'reporter||name',
      ],
      limit: 10000,
      page: 1,
      additionalCustomSearch: this.itemsService.prepareFilterForAssignee(filterCard.assignee),
    });
  }

  private formatKpiInformation(kpiInformation: IItemsKpiInformation[]): TKpiCard {
    this.resetKpiCardCounts();

    return kpiInformation.reduce((acc: TKpiCard, kpiInfo: IItemsKpiInformation) => {
      acc[kpiInfo.boardItemConfigurationItemType].count += kpiInfo.count;
      acc[kpiInfo.currentWorkflowStepStatusCategory].count += kpiInfo.count;

      return acc;
    }, this.kpiCardDetails);
  }

  private resetKpiCardCounts(): void {
    this.kpiCardOrder.forEach((kpiCardKey: TKpiCardKey) => {
      this.kpiCardDetails[kpiCardKey].count = 0;
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
