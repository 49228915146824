import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { IItemBase } from '../../../pages/items/items.interface';
import { UserChipComponent } from '../user-chip/user-chip.component';

export type TItemCardData = Pick<IItemBase, 'id' | 'key' | 'name' | 'createdAt'> & { assigneeName: string | null };

@Component({
  imports: [UserChipComponent, MatCard, NgClass],
  selector: 'app-item-card',
  standalone: true,
  styleUrl: './item-card.component.scss',
  templateUrl: './item-card.component.html',
})
export class ItemCardComponent {
  @Input() public isDataLoading = false;
  @Input({ required: true }) public item!: TItemCardData;

  @Output() cardClick: EventEmitter<void> = new EventEmitter();

  public onCardClick(): void {
    if (this.isDataLoading) {
      return;
    }

    this.cardClick.emit();
  }
}
