import { AsyncPipe, Location, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatError } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { find } from 'lodash';
import { BehaviorSubject, Observable, merge, of, startWith, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageHeaderService } from '../../../layout/page-header/page-header.service';
import { DateRangePickerComponent } from '../../../shared/components/date-range-picker/date-range-picker.component';
import { IDateRangePickerDate } from '../../../shared/components/date-range-picker/date-range-picker.interface';
import { IDropdownOption, MatDropdown } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IDropdownSettings } from '../../../shared/components/mat-dropdown/scw-mat-dropdown.interface';
import { ELoadStatus } from '../../../state/state.interface';
import { IBoard } from '../../settings/boards/boards.interface';
import { IBoardTreeStructure, TUrlItemScope } from '../items.interface';
import {
  IItemsFilterCardFields,
  TItemScope,
  TItemType,
  TItemsFilterCardFormControl,
} from './items-filter-card.interface';
import { IItemsFilterCardState, ItemsFilterCardStore } from './items-filter-card.store';

export interface IFilterDetails {
  data: Observable<IDropdownOption[]>;
  loading: Observable<ELoadStatus>;
}

@Component({
  imports: [
    ReactiveFormsModule,
    MatCard,
    MatCardContent,
    DateRangePickerComponent,
    MatDropdown,
    AsyncPipe,
    MatButtonToggleGroup,
    MatButtonToggle,
    TranslateModule,
    MatButton,
    NgIf,
    MatError,
  ],
  providers: [ItemsFilterCardStore],
  selector: 'app-items-filter-card',
  standalone: true,
  styleUrl: './items-filter-card.component.scss',
  templateUrl: './items-filter-card.component.html',
})
export class ItemsFilterCardComponent implements OnInit {
  @Input() public boardTreeStructure: IBoardTreeStructure[] = [];
  @Input() public initialBoard: IDropdownOption | undefined;
  @Input() public itemScopeValue: TUrlItemScope = null;

  @Output() public onApply = new EventEmitter<Partial<IItemsFilterCardFields>>();

  public readonly assigneeDropdownConfigurations: IDropdownSettings = {
    hasMatError: false,
    text: this.translate.instant('filterCard.assignee'),
  };
  public readonly boardDropdownConfigurations: IDropdownSettings = {
    hasMatError: false,
    text: this.translate.instant('filterCard.board'),
  };
  public readonly workflowStepDropdownConfigurations: IDropdownSettings = {
    hasMatError: false,
    isClientSide: true,
    text: this.translate.instant('filterCard.workflowStep'),
  };
  public readonly itemCategoryDropdownConfigurations: IDropdownSettings = {
    hasMatError: false,
    isClientSide: true,
    text: this.translate.instant('filterCard.itemCategory'),
  };
  public dates: IDateRangePickerDate = {
    endDate: dayjs.tz().endOf('day'),
    startDate: dayjs.tz().subtract(6, 'days').startOf('day'),
  };
  public optionsList: Record<string, IFilterDetails> = {};
  public formGroup: FormGroup<TItemsFilterCardFormControl> = new FormGroup({} as TItemsFilterCardFormControl);
  public showApply!: Observable<boolean>;
  public itemType: TItemType = 'all';
  public itemScope: TItemScope = 'family';

  private readonly filterNames: (keyof TItemsFilterCardFormControl)[] = [
    'assignee',
    'board',
    'workflowStep',
    'itemCategory',
    'itemType',
    'itemScope',
  ];
  private datesChanged: BehaviorSubject<undefined> = new BehaviorSubject<undefined>(undefined);
  private initialDateChange = true;
  private initialSubmit = true;

  constructor(
    private readonly store: ItemsFilterCardStore,
    private readonly location: Location,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.prepareFormGroup();
    this.prepareOptionsList();
    this.onSubmit();

    this.showApply = merge(
      this.formGroup.valueChanges.pipe(map(() => true)),
      this.onApply.pipe(map(() => false)),
      this.datesChanged.pipe(map(() => !this.initialDateChange)),
    ).pipe(startWith(false));
  }

  public onSubmit(): void {
    ItemsFilterCardStore.submitSubject.next(undefined);
    this.onApply.emit({ ...this.formGroup.value, dateRange: this.dates });

    if (this.initialSubmit) {
      this.initialSubmit = false;

      return;
    }

    this.handleUrlAndTitleChanges();
  }

  public datesChange(dates: IDateRangePickerDate | undefined): void {
    this.initialDateChange = false;

    if (!dates?.endDate || !dates?.startDate) {
      return;
    }

    this.datesChanged.next(undefined);
  }

  public searchAssignees(search?: string): void {
    this.store.getAssigneeData({
      fields: ['name'],
      limit: 20 + (search ? 0 : this.formGroup.controls.assignee.value?.length ?? 0),
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
    });
  }

  public searchBoards(search?: string): void {
    this.store.getBoardData({
      fields: ['name', 'key'],
      limit: 20 + (search ? 0 : this.formGroup.controls.board.value?.length ?? 0),
      ...(search ? { search: { searchedFields: ['name', 'key'], searchText: search } } : {}),
    });
  }

  private prepareOptionsList(): void {
    this.filterNames.forEach((filterName: string): void => {
      this.optionsList[filterName] = this.getFilterDetails(filterName);
    });
  }

  private prepareFormGroup(): void {
    this.filterNames.forEach((filterName: keyof TItemsFilterCardFormControl): void => {
      this.formGroup.addControl(filterName, new FormControl(this.getDefaultValue(filterName)) as FormControl);
    });
  }

  private getDefaultValue(filterName: string): string | IDropdownOption[] | null {
    switch (filterName) {
      case 'itemType':
        return 'all';
      case 'itemScope':
        return this.itemScopeValue === 'self' ? 'self' : 'family';
      case 'board':
        return this.itemScopeValue && this.initialBoard ? [this.initialBoard] : null;
      default:
        return null;
    }
  }

  private getFilterDetails(filterName: string): IFilterDetails {
    switch (filterName) {
      case 'itemCategory':
        this.store.getItemCategoryData();

        return {
          data: this.store.itemCategories,
          loading: this.store.select((state) => state.itemCategoriesLoading),
        };
      case 'workflowStep':
        this.store.getWorkflowStepData();

        return {
          data: this.store.workflowSteps,
          loading: this.store.select((state) => state.workflowStepsLoading),
        };
      case 'board':
        this.searchBoards();

        return {
          data: this.store.boards,
          loading: this.store.select((state) => state.boardsLoading),
        };
      case 'assignee':
        this.searchAssignees();

        return {
          data: this.store.assignees,
          loading: this.store.select((state) => state.assigneesLoading),
        };
      default:
        return {
          data: of([]),
          loading: of(ELoadStatus.success),
        };
    }
  }

  private handleUrlAndTitleChanges(): void {
    const currentUrl: string = this.location.path();
    const scope: TUrlItemScope = this.location.path().split('/')[3] as TUrlItemScope;
    const boardKey: string | null = this.location.path().split('/')[2] as string;
    const isSelfSelected: boolean = this.formGroup.get('itemScope')?.value === 'self';
    const selectedBoards: IDropdownOption[] | null | undefined = this.formGroup.get('board')?.value;
    const selectedScope: TUrlItemScope = this.formGroup.get('itemScope')?.value === 'self' ? 'self' : 'all';
    let newUrl: string = currentUrl;
    let selectedBoard: IBoard | undefined;

    if (!selectedBoards?.length || selectedBoards?.length !== 1) {
      this.location.go('/items');
      PageHeaderService.setPageHeader({
        icon: 'format_list_bulleted',
        showFilterButton: true,
        showFilterCard: true,
        subtitle: '',
        title: this.translate.instant('page.items.title'),
      });

      return;
    }

    if (scope === 'all' && isSelfSelected) {
      newUrl = currentUrl.replace(/all.*/, 'self');
    } else if (scope === 'self' && !isSelfSelected) {
      newUrl = currentUrl.replace(/self.*/, 'all');
    }

    this.store
      .select((state: IItemsFilterCardState) => state.boards)
      .pipe(take(1))
      .subscribe((boards: IBoard[]) => {
        selectedBoard = find(boards, { id: selectedBoards?.[0].id });

        if (selectedBoard && selectedBoards?.length) {
          newUrl = boardKey
            ? newUrl.replace(new RegExp(`${boardKey}`), selectedBoard.key)
            : newUrl.replace(/items.*/, `items/${selectedBoard.key}/${selectedScope}`);

          PageHeaderService.setPageHeader({
            icon: 'grid_view',
            subtitle: selectedBoard.key,
            title: selectedBoard.name,
          });
        }
      });

    this.location.go(newUrl);
  }
}
