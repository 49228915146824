<mat-card class="mb-3 p-1 item-card" [ngClass]="{ 'cursor-pointer': !isDataLoading }" (click)="onCardClick()">
  <div class="title">
    <span class="subtitle m-2">
      {{ item.key }}
    </span>
    <span class="title-text">
      {{ item.name }}
    </span>
  </div>
  <div class="row white-bg">
    <div class="col-6 text-left">
      <app-user-chip [name]="item.assigneeName"></app-user-chip>
    </div>
    <div class="col-6 text-right align-content-end">
      <span class="subtitle d-block">
        {{ item.createdAt }}
      </span>
    </div>
  </div>
</mat-card>
