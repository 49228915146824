import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { get } from 'lodash';
import * as momentTimezone from 'moment-timezone';
import { IDropdownOption } from '../shared/components/mat-dropdown/mat-dropdown.component';
import { UserEffects } from '../state/user/user.effects';
import { EDateUnits, IDateUnits } from './date-utilities.interface';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const MILLISECONDS_IN_A_SECOND = 1000;
export const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * 60;
export const MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_MINUTE * 60;
export const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_AN_HOUR * 24;
export const MILLISECONDS_IN_A_MONTH = MILLISECONDS_IN_A_DAY * 30;
export const MILLISECONDS_IN_A_YEAR = MILLISECONDS_IN_A_DAY * 365;

export class DateUtilitiesService {
  public static getTimezoneOptions(): IDropdownOption<string>[] {
    return momentTimezone.tz.names().reduce((names: IDropdownOption<string>[], name: string) => {
      if (name.includes('/')) {
        names.push({ id: name, name });
      }

      return names;
    }, []);
  }

  public static convertUserDateToUTCString(date: Dayjs): string {
    if (!get(date, '$x.$timezone')) {
      return dayjs.tz(date.format(API_DATE_FORMAT)).utc().format(API_DATE_FORMAT);
    }

    return date.utc().format(API_DATE_FORMAT);
  }

  public static convertUserDateStringToUTCString(date: string): string {
    return dayjs.tz(date, UserEffects.getUser().configuration.timezone).utc().format(API_DATE_FORMAT);
  }

  public static convertUTCToUserFormatted(dateToFormat: string, includeTime?: boolean): string {
    const { timezone: userTimezone, dateFormat } = UserEffects.getUser().configuration;

    return dayjs
      .tz(dateToFormat, 'UTC')
      .tz(userTimezone)
      .format(`${dateFormat}${includeTime ? ' LT' : ''}`);
  }

  public static convertUTCToUserTimezone(dateToFormat: string): Dayjs {
    return dayjs.tz(dateToFormat, 'UTC').tz(UserEffects.getUser().configuration.timezone);
  }

  public static buildDayjsFromISO8601DateString(dateString: string): Dayjs {
    return dayjs(dateString);
  }

  public static getNowInUTC(): Dayjs {
    return dayjs.utc();
  }

  public static calculateTimeDifferenceInMilliseconds(date1: Dayjs, date2: Dayjs): number {
    return date1.diff(date2);
  }

  public static getTimeUnitsInMilliseconds<U extends EDateUnits[]>(
    milliseconds: number,
    units: U,
  ): Pick<IDateUnits, U[number]> {
    const timeUnitMap = {
      [EDateUnits.years]: MILLISECONDS_IN_A_YEAR,
      [EDateUnits.months]: MILLISECONDS_IN_A_MONTH,
      [EDateUnits.days]: MILLISECONDS_IN_A_DAY,
      [EDateUnits.hours]: MILLISECONDS_IN_AN_HOUR,
      [EDateUnits.minutes]: MILLISECONDS_IN_A_MINUTE,
      [EDateUnits.seconds]: MILLISECONDS_IN_A_SECOND,
    };

    const result = {} as Pick<IDateUnits, U[number]>;

    let remainingMilliseconds = milliseconds;

    for (const unit of units) {
      const unitValue = Math.floor(remainingMilliseconds / timeUnitMap[unit]);
      result[unit as keyof typeof result] = unitValue;

      remainingMilliseconds = remainingMilliseconds % timeUnitMap[unit];
    }

    return result;
  }

  public static isDateLaterThenDate(date1: Dayjs, date2: Dayjs): boolean {
    return date1.isAfter(date2);
  }
}
