import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, mergeMap, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IDropdownOption } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { ELoadStatus } from '../../../state/state.interface';
import { UserEffects } from '../../../state/user/user.effects';
import { ICurrentUser, IUser } from '../../../state/user/user.interface';
import { IDefaultFieldOption } from '../../../utilities/default-field-options-utilities.service';
import {
  HttpUtilitiesService,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { IBoard } from '../../settings/boards/boards.interface';
import { EUserAssignee } from '../items.interface';

export interface IItemsFilterCardState {
  assignees: IDropdownOption[];
  assigneesLoading: ELoadStatus;
  boards: IBoard[];
  boardsLoading: ELoadStatus;
  itemCategories: IDropdownOption[];
  itemCategoriesLoading: ELoadStatus;
  workflowSteps: IDropdownOption[];
  workflowStepsLoading: ELoadStatus;
}

@Injectable()
export class ItemsFilterCardStore extends ComponentStore<IItemsFilterCardState> {
  public user: ICurrentUser = UserEffects.getUser();
  public static readonly submitSubject: BehaviorSubject<undefined> = new BehaviorSubject<undefined>(undefined);
  public readonly itemCategories: Observable<IDropdownOption[]> = this.select((state: IItemsFilterCardState) =>
    state.itemCategories.map((item) => ({
      id: item.id,
      name: item.isDefault ? this.translate.instant(`system.label.${item.name}`) : item.name,
    })),
  );
  public readonly workflowSteps: Observable<IDropdownOption[]> = this.select((state: IItemsFilterCardState) =>
    state.workflowSteps.map((item) => ({
      id: item.id,
      name: this.translate.instant(`system.label.${item.name}`),
    })),
  );
  public readonly boards: Observable<IDropdownOption[]> = this.select((state: IItemsFilterCardState) =>
    state.boards.map((item) => ({
      id: item.id,
      name: `${item.name} - ${item.key}`,
    })),
  );
  public readonly assignees: Observable<IDropdownOption[]> = this.select((state: IItemsFilterCardState) => {
    return state.assignees.map(
      (item): IDefaultFieldOption => ({
        id: item.id,
        name: item.name,
      }),
    );
  }).pipe(
    mergeMap((assignees: IDefaultFieldOption[]) => {
      return of([
        { id: this.user.id, name: this.translate.instant('filterCard.currentUser') },
        { id: EUserAssignee.unassignedUser, name: this.translate.instant('page.items.label.unassigned') },
        ...assignees.filter((assignee) => assignee.id !== this.user.id),
      ]);
    }),
  );

  readonly getItemCategoryData = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);
        this.patchState({ itemCategoriesLoading: ELoadStatus.loading });

        return this.getItemCategories(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IDropdownOption>) =>
              this.patchState({
                itemCategories: response.data,
                itemCategoriesLoading: ELoadStatus.success,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getWorkflowStepData = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000).set('sort', 'id,ASC');
        this.patchState({ workflowStepsLoading: ELoadStatus.loading });

        return this.getWorkflowSteps(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IDropdownOption>) =>
              this.patchState({
                workflowSteps: response.data,
                workflowStepsLoading: ELoadStatus.success,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getBoardData = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ boardsLoading: ELoadStatus.loading });
        const parameters = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getBoards(parameters).pipe(
          tapResponse(
            (response) =>
              this.patchState({
                boards: response.data,
                boardsLoading: ELoadStatus.success,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getAssigneeData = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ assigneesLoading: ELoadStatus.loading });
        const parameters = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getUsers(parameters).pipe(
          tapResponse(
            (response) =>
              this.patchState({
                assignees: response.data,
                assigneesLoading: ELoadStatus.success,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      assignees: [],
      assigneesLoading: ELoadStatus.initial,
      boards: [],
      boardsLoading: ELoadStatus.initial,
      itemCategories: [],
      itemCategoriesLoading: ELoadStatus.initial,
      workflowSteps: [],
      workflowStepsLoading: ELoadStatus.initial,
    });
  }

  private getItemCategories(params: HttpParams): Observable<IGetManyResponse<IDropdownOption>> {
    return this.http.get<IGetManyResponse<IDropdownOption>>('item-categories', { params });
  }

  private getWorkflowSteps(params: HttpParams): Observable<IGetManyResponse<IDropdownOption>> {
    return this.http.get<IGetManyResponse<IDropdownOption>>('workflow-steps', { params });
  }

  private getBoards(params: HttpParams): Observable<IGetManyResponse<IBoard>> {
    return this.http.get<IGetManyResponse<IBoard>>('boards', { params });
  }

  private getUsers(params: HttpParams): Observable<IGetManyResponse<IUser>> {
    return this.http.get<IGetManyResponse<IUser>>('users', { params });
  }
}
