<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="mb-3">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12 pb-2">
            <app-date-range-picker
              [(dates)]="dates"
              (datesChange)="datesChange($event)"
              [configurationInput]="{ showRanges: true, showErrors: false }"
            ></app-date-range-picker>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-2">
            <mat-dropdown
              [options]="(optionsList['assignee'].data | async)!"
              [configurationInput]="assigneeDropdownConfigurations"
              [formControl]="formGroup.controls.assignee"
              (search)="searchAssignees($event)"
            >
            </mat-dropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-2">
            <mat-dropdown
              [options]="(optionsList['board'].data | async)!"
              [configurationInput]="boardDropdownConfigurations"
              [formControl]="formGroup.controls.board"
              (search)="searchBoards($event)"
            >
            </mat-dropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-2">
            <mat-button-toggle-group
              formControlName="itemType"
              class="items-toggle-button-container"
              name="itemType"
              aria-label="Item Type"
              [(value)]="itemType"
              [hideSingleSelectionIndicator]="true"
            >
              <mat-button-toggle class="items-toggle-button" value="all">
                {{ 'page.items.button.all' | translate }}
              </mat-button-toggle>
              <mat-button-toggle class="items-toggle-button" value="issue">
                {{ 'page.items.button.issues' | translate }}
              </mat-button-toggle>
              <mat-button-toggle class="items-toggle-button" value="action">
                {{ 'page.items.button.actions' | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-2">
            <mat-dropdown
              [options]="(optionsList['workflowStep'].data | async)!"
              [configurationInput]="workflowStepDropdownConfigurations"
              [formControl]="formGroup.controls.workflowStep"
            >
            </mat-dropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-2">
            <mat-dropdown
              [options]="(optionsList['itemCategory'].data | async)!"
              [configurationInput]="itemCategoryDropdownConfigurations"
              [formControl]="formGroup.controls.itemCategory"
            >
            </mat-dropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 pb-2">
            <mat-button-toggle-group
              formControlName="itemScope"
              class="items-toggle-button-container"
              name="itemScope"
              aria-label="Item Scope"
              [(value)]="itemScope"
              [hideSingleSelectionIndicator]="true"
            >
              <mat-button-toggle class="items-toggle-button" value="family">
                {{ 'page.items.button.familyItems' | translate }}
              </mat-button-toggle>
              <mat-button-toggle class="items-toggle-button" value="self">
                {{ 'page.items.button.selfItems' | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div class="my-3" *ngIf="showApply | async">
      <mat-card>
        <mat-card-content>
          <div class="d-flex align-items-center">
            <button mat-flat-button color="accent" type="submit">{{ 'button.update' | translate }}</button>
            <span class="p-xl-1 grey-text">{{ 'system.message.filterCardUpdateText' | translate }}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
